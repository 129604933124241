const TrapTabHandler = (firstRef: HTMLElement, lastRef: HTMLElement): (() => void) => {
  const first = firstRef;
  const last = lastRef;

  const TrapTabEventHandler = (e: KeyboardEvent): void => {
    const isTabPressed = e.key === "Tab" || e.keyCode === 9;
    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      if (document.activeElement === first) {
        last.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === last) {
        first.focus();
        e.preventDefault();
      }
    }
  };

  document.addEventListener("keydown", TrapTabEventHandler);
  return (): void => {
    document.removeEventListener("keydown", TrapTabEventHandler);
  };
};

export const cookiebotTabTrapper = (): void => {
  let removeTrapperEvent: () => void;

  const loadTrapper = (tabOpen: boolean) => {
    if (removeTrapperEvent) {
      removeTrapperEvent();
    }

    const firstEl = document.getElementById("CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection");
    const lastEl = !tabOpen
      ? document.getElementById("CybotCookiebotDialogBodyLevelDetailsButton")
      : (document.getElementById("CybotCookiebotDialogDetailFooter").lastElementChild as HTMLElement);

    removeTrapperEvent = TrapTabHandler(firstEl, lastEl);
  };

  const reloadTrapper = () => {
    setTimeout(() => {
      const isOpen = document.querySelector<HTMLElement>("#CybotCookiebotDialogDetail").style.display === "block";
      loadTrapper(isOpen);
    }, 50);
  };

  const getShowMoreButton = () => document.querySelector<HTMLElement>("#CybotCookiebotDialogBodyLevelDetailsButton");
  const removeShowMoreEvent = () => {
    getShowMoreButton().removeEventListener("click", reloadTrapper);
  };

  window.addEventListener("CookiebotOnDialogDisplay", () => {
    getShowMoreButton().addEventListener("click", reloadTrapper);
    loadTrapper(false);
  });

  window.addEventListener("CookiebotOnAccept", () => {
    if (removeTrapperEvent) {
      removeTrapperEvent();
      removeShowMoreEvent();
    }
  });

  window.addEventListener("CookiebotOnDecline", () => {
    if (removeTrapperEvent) {
      removeTrapperEvent();
      removeShowMoreEvent();
    }
  });
};
