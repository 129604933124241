import { pushToDataLayer } from "@shared/Analytics/data/data-layer.facade";

export type GA4B2BSubscriptionEvent = {
  event: "mobilabonnemnt_checkout";
  item_product_name_list: string;
  item_business_unit: "B2B";
  item_product_page?: string;
  item_detail_page?: string;
  element_cta: GA4B2BSubscriptionCTA;
};

type GA4B2BSubscriptionCTA = "bestill, les mer" | string;

const B2B_SUBSCRIPTION_ELEMENT_DATA_ID = "B2B Mobilabonnement";
const PRODUCT_LIST_PAGE_PATH = "/bedrift/mobil/abonnement";

export const bootstrapB2BSubscriptionAnalytics = () => {
  const elementsToTrack = document.querySelectorAll(`[data-id="${B2B_SUBSCRIPTION_ELEMENT_DATA_ID}"] [data-id] a`);

  elementsToTrack.forEach((element) => {
    element.addEventListener("click", () => handleTrackingEvent(element));
  });
};

const handleTrackingEvent = (element: Element): void => {
  const dataId = element.closest("[data-id]")?.getAttribute("data-id") || "";

  const event: GA4B2BSubscriptionEvent = {
    event: "mobilabonnemnt_checkout",
    item_product_name_list: dataId || "",
    item_business_unit: "B2B",
    element_cta: element.textContent || "",
    ...(onProductListPage() ? { item_product_page: "product_list_page" } : { item_detail_page: dataId }),
  };

  pushToDataLayer(event);
};

function onProductListPage() {
  return new RegExp(`^${PRODUCT_LIST_PAGE_PATH}/?$`, "i").test(window.location.pathname);
}
